export function isMobile() {
  return navigator.userAgent.match(
    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
  )
}

export async function uniJump(from: string) {
  if (from) {
    // 跳到登录前的from
    console.log("jump to 'from' :", from)
    window.location.href = from
  } else {
    window.location.href = "/"
  }
}
export const ssoApiPath = "/api/discourseSso"

function getRootDomain(): string {
  return window.location.hostname.split(".").slice(-2).join(".")
}

export function getApiURL(): string {
  let path = "/api"
  if (import.meta.env.DEV) {
    return path
  }

  return `${import.meta.env["VITE_API_BACKEND_PREFIX"] || ""}.${getRootDomain()}${path}`
}
