import {
  defineComponent,
  ref,
  createVNode,
  render,
  Component,
  VNodeProps,
  Transition,
  PropType,
  Ref,
} from "vue"
import { sleep } from "@/mobile/utils"
import CloseSvg from "@/assets/close_gray.svg"
const toast = defineComponent({
  props: {
    show: Object as PropType<Ref<boolean>>,
    title: String,
    icon: String,
    duration: Number,
    width: String,
    captureEventP: {
      type: Boolean,
      default: true,
    },
    closeHandler: Function as PropType<() => void>,
  },
  setup(props) {
    const icon = () => {
      return (
        props.icon && (
          <img
            src={props.icon}
            class="w-35px h-35px mx-auto rounded-full animate-spin"
            animate="spin"
          />
        )
      )
    }
    const closeIcon = () => {
      return (
        props.closeHandler && (
          <img
            onClick={() => {
              Toast.close()
              props.closeHandler!()
            }}
            class="h-10px w-10px absolute right-12px top-12px"
            src={CloseSvg}></img>
        )
      )
    }
    return () => {
      return (
        <Transition
          enter-active-class="animate-fade-in animate-duration-300"
          leave-active-class="animate-fade-out animate-duration-300">
          <div
            id="lzc-toast"
            v-show={props.show?.value}
            flex="~"
            class={
              "absolute w-full h-full z-99 " +
              (props.captureEventP && "pointer-events-none")
            }>
            <div
              class="mx-auto my-auto text-center "
              w-screen
              bg-gray
              style={`width: ${props.width}`}>
              <div
                flex="~ col"
                class="space-y-4px rounded-8px relative pointer-events-auto"
                style={{ padding: props.icon ? "20px" : "12px" }}
                bg="black/80">
                {closeIcon()}
                {icon()}
                <div flex="~ wrap" text="16px white mx-auto" max-w-10px>
                  {props.title}
                </div>
              </div>
            </div>
          </div>
        </Transition>
      )
    }
  },
})
export interface ToastProps {
  show?: Ref<boolean>
  title: string
  icon?: string
  duration: number
  captureEventP?: boolean
  width?: string
  closeHandler?: () => void
}
export interface ToastReturn {
  destory: () => void
  show: () => void
}

class Toast {
  options: ToastProps
  container: HTMLElement
  toast: Component
  animateDuration: number
  displaying: boolean
  visiable: Ref<boolean>
  constructor(options: ToastProps) {
    this.visiable = ref(false)
    this.options = { ...options, show: this.visiable }
    this.container = document.createElement("div")
    this.toast = toast
    this.animateDuration = 300
    this.displaying = false
  }
  async show() {
    const props = { ...(this.options as VNodeProps) }
    const vm = createVNode(this.toast, props)
    render(vm, this.container)

    document.body.insertBefore(this.container, document.body.firstChild)
    this.visiable.value = true
    this.displaying = true
    // 根据 duration 自动删除 toast
    setTimeout(() => {
      this.destory()
    }, this.options.duration)
    await sleep(this.options.duration + this.animateDuration)
  }

  public async destory() {
    // 判断是否显示中 防止无效移除
    if (this.displaying) {
      this.visiable.value = false
      await new Promise<void>((done) =>
        setTimeout(() => {
          document.body.removeChild(this.container)
          this.displaying = false
          done()
        }, this.animateDuration - 10)
      )
    }
  }
  public static close() {
    const toast = document.querySelector("#lzc-toast")
    toast?.remove()
  }
}

export default Toast
