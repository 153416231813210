import { computed, ref } from "vue"
import { defineStore } from "pinia"

import { publicAccountService as pa } from "@/shared/api"
import { User } from "@lazycatcloud/public-account"
import { hdlRequest } from "@/mobile/utils/request"
import { getUserInfo } from "@/shared/api/playground"
import { useRouter } from "vue-router"

interface UserInfoResponse {
  /**
   * 总获赞数，包括评论和攻略
   */
  receiveThumbs: number
  /**
   * 关注数
   */
  follows: number
  /**
   * 粉丝
   */
  fans: number
}
export type UserType = User & UserInfoResponse

// 根据user的状态判断是否登陆
export const isLogin = ref(false)

const useUserStore = defineStore("user", () => {
  const router = useRouter()
  const playgroundBaseUrl = "https://playground.lazycat.cloud/#"
  const user = ref<null | UserType>(null)

  const roles = ref<string[]>([])

  const isManaged = computed<boolean>(() => {
    if (!roles.value.length) return false
    for (const role of roles.value) {
      if (role == "owner" || role == "superadmin") {// 只有站长和超级管理员才有权限
        return true
      }
    }
    return false
  })

  async function fetchCurrentuser() {
    try {
      const fieldsToKeep = ["fans", "follows", "receiveThumbs"]
      const _user = await pa.current()
      const resp = await getUserInfo(_user.id)
      user.value = {
        ..._user,
        ...Object.fromEntries(
          Object.entries(resp).filter(([key]) => fieldsToKeep.includes(key))
        ),
      } as UserType
      isLogin.value = true
      roles.value = _user.roles.split(",")
    } catch (e) {
      roles.value = []
      user.value = null
      isLogin.value = false
    }
  }

  async function setNickname(nickname: string) {
    if (!user.value?.id) return
    const { description, homePage } = user.value
    await pa.updateUserInfo(user.value.id, { nickname, description, homePage })
    await fetchCurrentuser()
  }

  async function setDescription(description: string) {
    if (!user.value?.id) return
    const { nickname, homePage } = user.value
    await pa.updateUserInfo(user.value.id, { nickname, description, homePage })
    await fetchCurrentuser()
  }

  function openPlaygroundPage(subPath: string) {
    if (!user.value) {
      router.push("/account/login-phone")
    } else {
      subPath = subPath.replace("$UID", user.value.id.toString())
        ; (window as any).android_window_operator.openNewActivity(
          playgroundBaseUrl + subPath
        )
    }
  }

  async function init() {
    fetchCurrentuser()
  }

  init()

  return {
    isManaged,
    roles,
    user,
    fetchCurrentuser,
    openPlaygroundPage,
    setNickname: hdlRequest(setNickname),
    setDescription: hdlRequest(setDescription),
  }
})

export default useUserStore
