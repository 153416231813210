<script setup lang="ts">
// import { IonRouterOutlet } from "@lazycatcloud/router"
import axios from "axios"
import { onMounted, ref } from "vue"
import { isMobile } from "./shared/utils"

const uuid = ref("")
onMounted(() => {
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  uuid.value = urlParams.get("uuid") || ""
})

window.addEventListener("beforeunload", async () => {
  if (uuid.value) {
    await axios.post(
      `https://account.lazycat.cloud/api/events/emit?uuid=${uuid.value}&msg=close`
    )
  }
})
</script>
<template>
  <template v-if="isMobile()">
    <router-view />
  </template>

  <template v-else>
    <router-view />
  </template>
</template>
<style>
.el-confirm-button{
  background-color: #2EC1CC!important;
  border-color: #2EC1CC!important;
}
.el-confirm-button:hover{
  background-color: #2EC1CC!important;
  border-color: #2EC1CC!important;
  opacity: 45%!important;
}
</style>