import type { Ref } from "vue"

// 延时
export async function sleep(duration: number) {
  return new Promise((good: any) => setTimeout(() => good(), duration))
}

/**
   倒计时工具
   @boundsTime 倒计时时间(s)，表示需要倒计时多久
   @timer 计时变量，内部会自动减少
 */
export function CounterDown(
  timer: Ref<number>,
  boundsTime: number,
  cb?: () => void
) {
  // 实现原理，内部依次递减倒计时，到达倒计时时将递减清除。
  timer.value = boundsTime
  const timerInterval = setInterval(() => {
    timer.value--
    if (timer.value < 0) {
      clearInterval(timerInterval)
      cb?.()
      timer.value = 0
    }
  }, 1000)
}
