import type { RouteRecordRaw } from "vue-router"
const RouterView = () => import("@/pc/views/RouterView.vue")

const routes: RouteRecordRaw[] = [
  {
    path: "/admin",
    redirect: "/admin/list",
    component: RouterView,
    children: [
      {
        path: "list",
        component: () => import("@/admin/list.vue"),
      },
    ],
  },
  {
    path: "/discourse",
    component: () => import("@/shared/views/sso.vue"),
    meta: {
      title: "论坛单点登录",
    },
  },
  {
    path: "/",
    redirect: "/account/login",
  },
  {
    path: "/account",
    redirect: "/account/login",
    component: RouterView,
    children: [
      {
        path: "login",
        component: () => import("../views/loginDialog/LoginBox.vue"),
      },
      {
        path: "register",
        component: () => import("../views/loginDialog/Register.vue"),
      },
      {
        path: "forget",
        component: () => import("../views/loginDialog/ForgetPassword.vue"),
      },
    ],
  },
  {
    path: "/intl/account",
    redirect: "/intl/account/login",
    component: RouterView,
    children: [
      {
        path: "login",
        component: () => import("../views/loginDialog/IntlLoginBox.vue"),
      },
      {
        path: "register",
        component: () => import("../views/loginDialog/IntlRegister.vue"),
      },
      {
        path: "forget",
        component: () => import("../views/loginDialog/ForgetPassword.vue"),
      },
    ],
  },
]

export default routes
