
       if (typeof window !== 'undefined') {
         function loadSvg() {
           var body = document.body;
           var svgDom = document.getElementById('__svg__icons__dom__');
           if(!svgDom) {
             svgDom = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
             svgDom.style.position = 'absolute';
             svgDom.style.width = '0';
             svgDom.style.height = '0';
             svgDom.id = '__svg__icons__dom__';
             svgDom.setAttribute('xmlns','http://www.w3.org/2000/svg');
             svgDom.setAttribute('xmlns:link','http://www.w3.org/1999/xlink');
           }
           svgDom.innerHTML = "<symbol  viewBox=\"0 0 8 6\" id=\"icon-agress\"><path d=\"M6.961.194a.584.584 0 0 1 .854-.017.658.658 0 0 1 .074.823l-.057.073-4.333 4.733a.583.583 0 0 1-.805.061l-.068-.065L.166 3.08A.65.65 0 0 1 0 2.627a.646.646 0 0 1 .188-.443.582.582 0 0 1 .788-.041l.066.063 2.026 2.24L6.96.194h.001Z\" fill=\"#FFF\" fill-rule=\"nonzero\" /></symbol><symbol  viewBox=\"0 0 12 20\" id=\"icon-back\"><path d=\"m.254 10.604 9.268 9.146c.22.216.538.3.836.22a.86.86 0 0 0 .612-.603.846.846 0 0 0-.224-.825L2.09 10l8.656-8.542a.846.846 0 0 0 0-1.208.874.874 0 0 0-1.224 0L.254 9.396a.845.845 0 0 0 0 1.208Z\" fill=\"#202020\" fill-rule=\"nonzero\" /></symbol><symbol  viewBox=\"0 0 16 16\" id=\"icon-close\"><g fill=\"#333\" fill-rule=\"nonzero\"><path d=\"M1.448 15.667a.849.849 0 0 1-1.2-1.2L14.473.242a.848.848 0 0 1 1.2 1.2L1.447 15.667Z\" /><path d=\"M.248 1.448a.848.848 0 1 1 1.2-1.2l14.225 14.225a.848.848 0 1 1-1.2 1.2L.248 1.448Z\" /></g></symbol><symbol  viewBox=\"0 0 5 10\" id=\"icon-more\"><path d=\"M4.885 5.302.672 9.875a.373.373 0 0 1-.38.11.412.412 0 0 1-.279-.302.452.452 0 0 1 .102-.412l3.935-4.27L.115.728a.453.453 0 0 1 0-.604.372.372 0 0 1 .557 0l4.213 4.573A.445.445 0 0 1 5 5c0 .11-.038.218-.115.302Z\" fill=\"#A5A5A5\" fill-rule=\"nonzero\" /></symbol>";
           body.insertBefore(svgDom, body.lastChild);
         }
         if(document.readyState === 'loading') {
           document.addEventListener('DOMContentLoaded', loadSvg);
         } else {
           loadSvg()
         }
      }
        
export default {}