import Toast from "@/mobile/widget/toast"

// 中心化账号请求请求
export function hdlRequest(callback: (...args: any[]) => Promise<any>) {
  return async (...args: any[]) => {
    // FIXME: 这里会有坑,如果确实需要返回null或undefined可能会拿到错误的结果
    const result = await callback(...args).catch((e) => {
      if ("message" in e) {
        fail(e.message)
      }
      return false
    })
    return result ?? true
  }
}

// 失败: 弹出气泡消息
export function fail(message: string) {
  setTimeout(() => {
    const errorToast = new Toast({ title: message, duration: 1000 })
    errorToast.show()
    return errorToast
  }, 100)
}
