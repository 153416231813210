import type { Router } from "vue-router"
import { publicAccountService as pa } from "@/shared/api"
import { isLogin } from "../store/user"

export function registerNavigationGuard(router: Router) {
  router.beforeEach(async (to, from) => {
    if (to.meta["needLogin"] && !isLogin.value) {
      const user = await pa.current().catch(() => false)
      if (!user) {
        return {
          path: "/account/login-phone",
          query: {
            from: to.path,
          },
        }
      }
    }
    // 自动添加重定向参数query
    if (to.matched[0].path.startsWith("/account")) {
      if (to.query["from"]) return true
      if (!from.query["from"]) return true
      return {
        path: to.path,
        query: { from: from.query["from"] },
      }
    }
  })
}
