import { createApp } from "vue"
import { createPinia } from "pinia"
import { isMobile } from "./shared/utils"
import piniaPluginPersistedstate from "pinia-plugin-persistedstate"
import App from "./App.vue"
import router from "./shared/router"
import { setNavAnimation } from "@lazycatcloud/router"
import { pageLeftToRight } from "./mobile/utils/animation"

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

import "virtual:svg-icons-register"
import "uno.css"
import "./shared/style/index.css"
import "@lazycatcloud/lzc-toolkit/dist/style.css"
if (isMobile()) {
  import("./mobile/style")
  setNavAnimation(pageLeftToRight)
} else {
  import("./pc/style")
}

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

const app = createApp(App)

app.use(pinia).use(router).use(ElementPlus)

app.mount("#app")
