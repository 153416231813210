import { isMobile } from "@/shared/utils"
import { createRouter, createWebHashHistory } from "@lazycatcloud/router"
import mobileRoute from "@/mobile/router"
import pcRoute from "@/pc/router"

const router = createRouter({
  history: createWebHashHistory(),
  routes: isMobile() ? mobileRoute.routes : pcRoute.routes,
})


const RouterView = () => import("@/mobile/views/RouterView.vue")
router.addRoute({
  path: "/email",
  redirect: "/email/verify",
  component: RouterView,
  children: [
    {
      path: "verify",
      component: ()=> import("@/pc/views/email_verify.vue"),
    },
  ],
})
export default router
