import type { RouteRecordRaw } from "vue-router"
const RouterView = () => import("@/mobile/views/RouterView.vue")

const routes: RouteRecordRaw[] = [
  {
    path: "/admin",
    redirect: "/admin/list",
    component: RouterView,
    children: [
      {
        path: "list",
        component: () => import("@/admin/list.vue"),
      },
    ],
  },
  {
    path: "/",
    redirect: "/account/login-phone",
  },
  {
    path: "/account",
    redirect: "/account/login-phone",
    component: RouterView,
    children: [
      {
        path: "login-passwd",
        component: () => import("../views/account/LoginByPasswd.vue"),
      },
      {
        path: "login-phone",
        component: () => import("../views/account/LoginByPhone.vue"),
      },
      {
        path: "register",
        component: () => import("../views/account/RegisterAccount.vue"),
      },
      {
        path: "forget",
        component: () => import("../views/account/ForgetPasswd.vue"),
      },
    ],
  },
  {
    path: "/setting",
    component: RouterView,
    redirect: "/setting/index",
    children: [
      {
        path: "index",
        component: () => import("../views/setting/index.vue"),
        meta: { needLogin: true },
      },
      {
        path: "profile",
        component: RouterView,
        redirect: "/setting/profile/index",
        meta: { needLogin: true },
        children: [
          {
            path: "index",
            component: () => import("../views/setting/profile/index.vue"),
          },
          {
            path: "nickname",
            component: () => import("../views/setting/profile/Nickname.vue"),
          },
          {
            path: "description",
            component: () => import("../views/setting/profile/Description.vue"),
          },
        ],
      },
      {
        path: "account-and-security",
        component: RouterView,
        redirect: "/setting/account-and-security/index",
        meta: { needLogin: true },
        children: [
          {
            path: "index",
            component: () =>
              import("../views/setting/account-and-security/index.vue"),
          },
          {
            path: "display-mob",
            component: () =>
              import(
                "../views/setting/account-and-security/DisplayMobilcPhone.vue"
              ),
          },
          {
            path: "verify-mob",
            component: () =>
              import(
                "../views/setting/account-and-security/VerifyMobilePhone.vue"
              ),
          },
          {
            path: "modify-mob/:token",
            name: "modify-mob",
            component: () =>
              import(
                "../views/setting/account-and-security/ModifyMobilePhone.vue"
              ),
          },
          {
            path: "set-passwd/:token?",
            name: "set-passwd",
            props: true,
            component: () =>
              import("../views/setting/account-and-security/SetPassword.vue"),
          },
          {
            path: "account-logout",
            component: () =>
              import("../views/setting/account-and-security/AccountLogout.vue"),
          },
        ],
      },
      {
        path: "privaty",
        component: RouterView,
        redirect: "/setting/privaty/index",
        meta: { needLogin: true },
        children: [
          {
            path: "index",
            component: () => import("../views/setting/privaty/index.vue"),
          },
          {
            path: "black-list",
            component: () => import("../views/setting/privaty/BlackList.vue"),
          },
        ],
      },
    ],
  },
  {
    path: "/discourse",
    component: () => import("@/shared/views/sso.vue"),
    meta: {
      title: "论坛单点登录",
    },
  },
]

export default routes
